<template>
  <div class="m-0 px-3 pt-4 pb-0 pr-2 recommended-readings-show">
    <back-button class="ml-4" />
    <h1 class="mb-4">{{ `View Info` }}</h1>
    <div class="cardStyle p-4 col-lg-4 col-sm-12" v-loading="loaders.fetching">
      <div class="border-bottom">
        <div class="d-flex justify-content-between align-items-center pb-2">
        <h1 class="text-break mr-5">{{recommendedReading.name}}</h1>
        <base-button type="dark-blue" outline size="xl" @click="$router.push({name:'admin.recommended-readings.edit',params:{id:recommendedReading.id}})">Edit</base-button>
      </div>
      <p class="status" :class="recommendedReading.status?'active':'inactive'">{{recommendedReading.status ? 'Active' : 'Inactive'}}</p>
      </div>
      <h3 class="my-3 description-heading">DESCRIPTION</h3>
      <p class="text-break description" v-html="convertStringToLink(recommendedReading.description)"></p>
    </div>
  </div>
</template>
<script>
import BackButton from "@/components/Router/BackButton";
import convertStringToAnchorTagMixin from "@/mixins/convertStringToAnchorTagMixin";

export default {
  components: {
    BackButton,
  },
  mixins:[convertStringToAnchorTagMixin],
  data() {
    return {
      loaders: {
        fetching: false,
        email: false,
      },
      recommendedReading: {}
    };
  },
  mounted() {
    this.getRecommendedReadingById();
  },
  methods: {
    async getRecommendedReadingById() {
            this.loaders.fetching = true;
            let id = this.$route.params.id;
            try {
                let response = await this.$store.dispatch('RecommendedReadingsModule/_getRecommendedReadingsById', { id });
                let {
                    data: { data },
                } = response;
                this.recommendedReading = data;
            } catch (error) {
                if(error?.response?.status == '404'){
                  this.$router.push({name:'admin.recommended-readings.index'});
                }

            }
            this.loaders.fetching = false;
    },
  },
  computed: {


  },
};
</script>
<style lang="scss">
.recommended-readings-show {
  .description{
    font-size: 12px;
    font-weight: normal;
    line-height: 17px;
  }
  .description-heading{
    font-size: 16px;
    font-weight: bold;
    line-height: 22px;
  }
  .status {
    text-align: center;
    background-color: black;
    font-weight: 600;
    font-size: 12px;
    color: white;
    padding: 0.15rem 0.95rem;
    border-radius: 20px;
    max-width: 76px;
    &.active {
      background-color: #94B527;
    }

    &.inactive {
      background-color: #BD3D55;
    }
  }
}
</style>  