<template>
  <div class="m-0 px-3 pt-4 pb-0 pr-2">
    <back-button class="ml-4" />
    <h1>Edit Reading</h1>
    <recommended-readings-form :recommended-reading="recommendedReading" :loader="loaders.submit || loaders.fetching" @submit="onSubmit" ref="form" />
  </div>
</template>
<script>
import BackButton from "@/components/Router/BackButton";
import generateErrorMessageMixin from '@/mixins/generateErrorMessageMixin';
import RecommendedReadingsForm from "@/views/Components/SuperAdmin/RecommendedReadings/Form.vue";


export default {
  components: {
    BackButton,
    RecommendedReadingsForm
  },
  mixins: [generateErrorMessageMixin],
  data() {
    return {
      loaders: {
        fetching: false,
        submit: false,
      },
      recommendedReading: {},
    };
  },
  mounted() {
    this.getRecommendedReadingById();
  },
  methods: {
    async onSubmit(fd) {
      this.loaders.submit = true;
      let validation = this.$refs.form.$refs.formValidator;
      let id = this.$route.params.id;
      try {
        let response = await this.$store.dispatch('RecommendedReadingsModule/_updateRecommendedReadings', { fd,id });

        let { data: { data, message } } = response;

        this.$notify.success({
          title: 'Recommended Readings',
          message: message
        });
        this.$router.push({ name: 'admin.recommended-readings.index' })
      } catch (error) {
        this.showErrorOnForm(error, 'Recommended Readings', validation)
      }
      this.loaders.submit = false;
    },
    async getRecommendedReadingById() {
        this.loaders.fetching = true;
        let id = this.$route.params.id;
        try {
            let response = await this.$store.dispatch('RecommendedReadingsModule/_getRecommendedReadingsById', { id });
            let {
                data: { data },
            } = response;
            this.recommendedReading = data;
        } catch (error) {
            if(error?.response?.status == '404'){
              this.$router.push({name:'admin.recommended-readings.index'});
            }

        }
        this.loaders.fetching = false;
    },
  }

};
</script>
<style lang="scss"></style>  